import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from './../assets/images/logo.svg';
import linkedinIcon from './../assets/icons/mdi_linkedin.svg';
import twitterIcon from './../assets/icons/prime_twitter.svg';
import instagramIcon from './../assets/icons/ri_instagram-fill.svg';
import './../App.css';

function Home() {
  const [email, setEmail] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    // Dynamically load the ElevenLabs AI script
    const script = document.createElement('script');
    script.src = 'https://elevenlabs.io/convai-widget/index.js';
    script.async = true;
    script.type = 'text/javascript';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const hubspotData = new URLSearchParams();
    hubspotData.append('email', email);

    try {
      const response = await axios.post(
        `https://forms.hubspot.com/uploads/form/v2/47055314/9a8c5201-3616-4ce2-b6ff-5f4e23248e64`,
        hubspotData,
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      );

      if (response.status === 200 || response.status === 204) {
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="social-container">
          <a href="https://x.com/flyzen1897" className="social-btn" target="_blank" rel="noopener noreferrer">
            <img alt='twitter' src={twitterIcon} />
          </a>
          <a href="https://www.linkedin.com/company/flyzenapp/" className="social-btn" target="_blank" rel="noopener noreferrer">
            <img alt='linkedin' src={linkedinIcon} />
          </a>
          <a href="https://www.instagram.com/flyzen.app/" className="social-btn" target="_blank" rel="noopener noreferrer">
            <img alt='instagram' src={instagramIcon} />
          </a>
        </div>

        <h1 className="Heading">Join our beta and fly with your zen</h1>
        <p className='secondary'>Calm your mind and ease your fear of flying with our guided meditations and expert tips. Find peace in the skies with FlyZen.</p>

        <div className="email-signup">
          {isSubmitted ? (
            <div className="thank-you-message">
              <h2 className='secondary-for-thankyou'>Thank you for subscribing!</h2>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="formcontainer">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="email-input"
                required
              />
              <button type="submit" className="submit-button">Send</button>
            </form>
          )}
        </div>

        <div className="note-container">
          <p className="note">
            <span role="img" aria-label="crown">👑</span> First <span className="highlight">1000 users</span> will receive a <span className="highlight">1 Year Free</span> access to all upcoming features!
          </p>
        </div>

        {/* ✅ ElevenLabs Conversational AI Widget */}
        <div className="elevenlabs-container">
          <elevenlabs-convai agent-id="4lpoFhstdUFZQ0xY3pcm"></elevenlabs-convai>
        </div>

        <p className="Copyrights">©Copyrights 2025. FlyZen Inc.</p>
      </header>
    </div>
  );
}

export default Home;
